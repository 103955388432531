/* span.ant-input-suffix{
    background-color: #fff;
} */

@media (max-width: 767px) {
   
    form#normal_login, .ant-row.ant-form-item, .ant-form-item-control-input, .ant-form-item-control-input-content, span.ant-input-affix-wrapper {
        width: 100%;
    }
    .col-md-6.jc_login.p-0 {
        width: 24rem;
        display: none;
    }
    .col-md-9.mx-auto {
        margin: 10px 0px;
    }
    .loginsection {
        display: none;
    }
    }