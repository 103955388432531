

.main_div{
    display: flex;
    justify-content: left;
    margin-bottom: 35px;
}
.calender {
    box-shadow: 1px 1px 8px #aaaaaa;
    /* width: 100%; */
    height: auto;
}

.react-calendar {
    max-width: 300px !important;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__navigation button {
    min-width: 34px !important;
    background: none;
}

.react-calendar__navigation button[disabled] {
    background-color: transparent;
}

button#dropdownMenuButton {
    background: #525256;
}

button#dropdownMenuButton:hover {
    background: #ff8f26 !important;
}