.App {
  text-align: center;
}
.ant-spin-nested-loading {
  width: 100%;
  /* overflow: initial; */
} 
/* .ant-form-item-control-input-content {
  margin-left: 10px;
} */
*{
  margin: 0;
  padding: 0;
}

.inputWrapper{
  position: relative;
}

.inputWrapper i{
  position: absolute;
  right: 10px;
  top:50%;
  transform: translateY(-50%);
}
.mb-1.filter-dropdown {
  width: 12%;
}
button.ant-btn.btn.btn-warning.my-3.mx-2 {
  color: white;
  background-color: #ff8f26;
}
button.edit-button {
  font-size: 20px !important;
  float: right;
}
.MuiTableCell-head {
  background: #525256 !important;
  color: #fff !important;
}
.control-label:after {
  content:" *";color:red;
}
a.nav-link.active {
  color: #f7a01d !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ant-row {
  display: block !important;
  margin-right: 0px !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.reduceopacity::placeholder{
  opacity: 60%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#justunique{
  background-color: #f7a01d !important;
}

#justunique:active{
  background-color: green !important;
}




