.active1{
    font-size: 14px;
    line-height: normal;
    font-weight: 500 !important;
    padding: 8px 12px;
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    width: 80px;
}
.active1:hover{
    background-color: #28a745;
    border-color: #28a745;
}

.inactive{
    font-size: 14px;
    line-height: normal;
    font-weight: 500 !important;
    padding: 8px 12px;
    width: 80px;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.inactive:hover{
    background-color: #dc3545;
    border-color: #dc3545;
}

.section-header{
    background-color: #f7a01d;
    color: #fff;
    padding: 12px 15px;
    line-height: normal;
    font-size: 28px;
    border-top-left-radius: 6px;
    margin-bottom: 15px;
    border-top-right-radius: 6px;
}

.text {
    color: #ff8e27;
	cursor: pointer;
}

/* .primaryAddress1{
	position: absolute;
    margin-top: -5%;
}
.mobileNo{
	position: absolute;
    margin-top: -5%;
}
.primaryState{
	position: absolute;
    margin-top: -5%;
} */





.form-style-3{
	max-width: 450px;
	font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
.form-style-3 label{
	display:block;
	margin-bottom: 10px;
}
.form-style-3 label > span{
	float: left;
	width: 100px;
	color: #ff8e27;
	font-weight: bold;
	font-size: 13px;
	text-shadow: 1px 1px 1px #fff;
}
.form-style-3 fieldset{
	/* border-radius: 10px; */
	/* -webkit-border-radius: 10px;
	-moz-border-radius: 10px; */
	margin: 0px 0px 10px 0px;
	/* border: 1px solid #FFD2D2; */
	padding: 20px;
	/* background: #FFF4F4; */
	/* box-shadow: inset 0px 0px 15px #FFE5E5;
	-moz-box-shadow: inset 0px 0px 15px #FFE5E5;
	-webkit-box-shadow: inset 0px 0px 15px #FFE5E5; */
}
.form-style-3 fieldset legend{
	color: #535356;
	/* border-top: 1px solid #FFD2D2;
	border-left: 1px solid #FFD2D2;
	border-right: 1px solid #FFD2D2;
	border-radius: 5px 5px 0px 0px; */
	/* -webkit-border-radius: 5px 5px 0px 0px;
	-moz-border-radius: 5px 5px 0px 0px; */
	/* background: #FFF4F4; */
	padding: 0px 8px 3px 8px;
	/* box-shadow: -0px -1px 2px #F1F1F1;
	-moz-box-shadow:-0px -1px 2px #F1F1F1;
	-webkit-box-shadow:-0px -1px 2px #F1F1F1; */
	font-weight: normal;
	font-size: 12px;
}
.text-white{
    color: #ffffff00 !important;
}
.form-style-3 textarea{
	width:250px;
	height:100px;
}
.form-style-3 input[type=text],
.form-style-3 input[type=date],
.form-style-3 input[type=datetime],
.form-style-3 input[type=number],
.form-style-3 input[type=search],
.form-style-3 input[type=time],
.form-style-3 input[type=url],
.form-style-3 input[type=email],
.form-style-3 select, 
.form-style-3 textarea{
	/* border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px; */
	border: 1px solid #535356;
	outline: none;
	color: #ff8e27;
	padding: 5px 8px 5px 8px;
	/* box-shadow: inset 1px 1px 4px #535356;
	-moz-box-shadow: inset 1px 1px 4px #535356;
	-webkit-box-shadow: inset 1px 1px 4px #535356; */
	background: #FFEFF6;
	width:50%;
}
.form-style-3  input[type=submit],
.form-style-3  input[type=button]{
	background: #eb643b;
	/* border: 1px solid #ff8e27; */
	padding: 5px 15px 5px 15px;
	color: #535356;
	/* box-shadow: inset -1px -1px 3px #ff8e27;
	-moz-box-shadow: inset -1px -1px 3px #ff8e27;
	-webkit-box-shadow: inset -1px -1px 3px #ff8e27;
	border-radius: 3px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;	 */
	font-weight: bold;
}
.required{
	color:red;
	font-weight:normal;
}
.ant-divider-horizontal.ant-divider-with-text {
    border-top-color: #000;
}