.sign_in {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign_in_title {
    margin-bottom: 30px !important;
}
/* .ant-modal-close-x {
    display: none;
} */

.maincontainer {
    display: flex;
    align-items: center;
    height: 100vh;
}
form#normal_login {
    text-align: left;
}


.jc_login {
    display: flex;
    width: 23rem;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    background-color: rgb(91 90 93 / 55%);
    /* background-image: url('../../../assets/img/Background-1-802x1080.jpg'); */
    /* background-image: url('../../../assets/img/newImg.jpg'); */
    background-color:#ff8f27a2;
    background-size: cover;
}
/* .login_jc{
    display: flex;
    align-items: center;
    height: 100%; 
    
} */

a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea {
    touch-action: manipulation;
    padding: 9px;
}
.col-md-9.mx-auto {
    height: 100vh;
    align-items: center;
    display: flex;
}
h1.Nametext_class {
    color:#ffff;
    font-size: 24px;
}

button.ant-btn.ant-btn-primary.login_employee {
    background: #ff8f26;
    border: #ff8f26;
    border-radius: 4px;
    width: auto;
    /* border-radius: 20px; */
}
input#normal_login_password {
    padding: 10px;
    margin-left:0px;
}
.ant-input-suffix {
    margin-left: 0px;
}
span.ant-input-suffix {
    background: #e9f0fe;
    padding: 0px 10px;
}
/* button.ant-btn.ant-btn-primary.login-form-button {
    border-radius: 20px;
} */

.maincontainer .ant-modal-mask{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.45); */
    /* background-image: linear-gradient(to right, rgb(124 213 255 / 18%), rgb(0 173 255 / 53%)); */
      background: linear-gradient( 
110deg
 , #ff8e27 60%, #535357 60%);
}

/* .loginModal .ant-modal-content{
    border-radius: 15px;
} */
img.login_logo {
    width: 70px;
    height: auto;
    display: block;
    margin: auto auto 2rem;
    object-fit: contain;
    position: relative;
    top: 25px;
}
.logintext{
    color: #ff8f26;
    font-size: 33px;
}
.loginbtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.loginwraper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    }
/* .login_jc{
    padding: 61px;
} */
img.loginbg {
    width: 100%;
}
button.ant-modal-close, .span.ant-modal-close-x {
    display: none;
}
/* .logo_css {
    background: #f89f4a;
    border-radius: 50%;
    width: 12rem;
    height: 12rem;
    position: absolute;
    top: -9rem;
    border: 10px solid #8c8c8c;
} */

.forgote {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-form-button {
    border-color: #ff8e27 !important;
    background: #FF8E28 !important;
    color: #ffff;
}
.frogotlink {
    color: #535357 !important;
    text-align: center;
}
.container{
    display: grid;
    width: -webkit-fill-available;
}
form#normal_login {
    width: 100%;
    /* max-width: 54%; */
}
h2.ant-typography.sign_in_title {
    position: relative;
    top: 3.5rem;
}
@media (max-width: 1100px) {
    img.loginbg {
        width: 100%;
    }
    .col-md-9.mx-auto {
        margin: 10px 0px;
    }
    
}
@media (max-width: 767px) {
   
form#normal_login, .ant-row.ant-form-item, .ant-form-item-control-input, .ant-form-item-control-input-content, span.ant-input-affix-wrapper {
    width: 100%;
}
.col-md-6.jc_login.p-0 {
    width: 24rem;
}
.col-md-9.mx-auto {
    margin: 10px 0px;
}
.loginsection {
    display: none;
}
}