.sign_in {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign_in_title {
    margin-bottom: 30px !important;
}
/* .ant-modal-close-x {
    display: none;
} */

.ant-modal-content, .ant-modal-body {
    padding: 15px; 
}

.maincontainer .ant-modal-mask{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.45); */
    /* background-image: linear-gradient(to right, rgb(124 213 255 / 18%), rgb(0 173 255 / 53%)); */
    background: linear-gradient( 
        110deg
         , #ff8e27 60%, #535357 60%);
}

.forgotModal .ant-modal-content{
    border-radius: 15px;
    position: relative;
    top: 148px;

}
form#email {
    width: 100%;
    /* margin-left: 72px;
    margin-top: 14px; */
}
.forgote {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.backLogin{
    color: #535357 !important;

}
.forgotpassclss{
    color: rgb(249 143 46);
}
.forgotpass{
    color: #ffff;
}
.loginbtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.forgotwraper{
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
}
img.forget {
    width: 300px;
}
@media (max-width: 767px) {
   
    form#normal_login, .ant-row.ant-form-item, .ant-form-item-control-input, .ant-form-item-control-input-content, span.ant-input-affix-wrapper {
        width: 100%;
    }
    .col-md-6.jc_login.p-0 {
        width: 24rem;
    }
    .col-md-9.mx-auto {
        margin: 10px 0px;
    }
    .loginsection {
        display: none;
    }
    }